import administrator from "./administrator";
import reseller from "./reseller";
import brand from "./brand";
import website from "./website";
import inventory from "./inv";
import store_location from "./store-location";
import hr from "./hr";
import server from "./server";

export default [
  /** GLOBAL ROUTES */
  { path: "*", component: () => import("../pages/global/error/404.vue") },
  {
    path: "/403",
    component: () => import("../pages/global/error/403.vue"),
  },
  // ================ Import External Route ================
  ...administrator,
  ...reseller,
  ...brand,
  ...website,
  ...inventory,
  ...store_location,
  ...hr,
  ...server,
  // ================ dashboard ================
  {
    name: "dashboard_index",
    path: "/dashboard",
    component: () => import("../pages/homepage/Dashboard.vue"),
  },
  {
    name: "dashboard_indexapp",
    path: "/dashboard-app",
    component: () => import("../pages/homepage/DashboardApp.vue"),
  },
  {
    name: "dashboard_indexwebsite",
    path: "/dashboard-website",
    component: () => import("../pages/homepage/DashboardWebsite.vue"),
  },
  {
    name: "dashboard_indextiktok",
    path: "/dashboard-tiktok",
    component: () => import("../pages/homepage/DashboardTiktok.vue"),
  },
  {
    name: "dashboard_indexvita",
    path: "/dashboard-vita",
    component: () => import("../pages/homepage/DashboardVita.vue"),
  },
  {
    name: "dashboard_indexhr",
    path: "/dashboard-hr",
    component: () => import("../pages/homepage/DashboardHr.vue"),
  },
  {
    name: "dashboard_indexreseller",
    path: "/dashboard-reseller",
    component: () => import("../pages/homepage/DashboardReseller.vue"),
  },
  {
    name: "dashboard_indexit",
    path: "/dashboard-it",
    component: () => import("../pages/homepage/DashboardIT.vue"),
  },
  {
    name: "profile_index",
    path: "/profile",
    component: () => import("../pages/global/profile/Profile.vue"),
  },
];
